<template>
    <div :class="'c-card c-' + index + ' c-' + type + (isSelected(index, card.id, card.checked) ? ' is-checked' : '') + (disabled ? ' is-disabled' : '')">
        <transition name="fade" mode="out-in">
            <observed-image class-name="c-interior__img" :src="card.image"/>
            <!--<img class="c-interior__img" :src="card.image" />-->
        </transition>

        <label class="custom-control-label" :for="card.id">
            <input type="radio" class="custom-control-input" :id="card.id" name="card" :value="card.name" :checked="isSelected(index, card.id, card.checked)" @change="onChange($event, card)" :disabled="disabled">
            <span class="custom-control-name">{{ card.name }}</span>
        </label>

        <router-link :to="'/detail/' + index + '/' + card.id" class="c-card__link" v-show="!disabled">
            <svg class="c-card__link-svg" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                <path class="c-card__link-bg" d="M7.5 0A7.5 7.5 0 1 1 0 7.5 7.5 7.5 0 0 1 7.5 0z"></path>
                <path class="c-card__link-border" d="M7.5 1A6.507 6.507 0 0 0 1 7.5C1 11.084 3.916 14 7.5 14S14 11.084 14 7.5 11.084 1 7.5 1m0-1a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15z"></path>
                <text class="c-card__link-text" transform="translate(6 11)" font-size="10" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="0">i</tspan></text>
            </svg>
            <span class="u-hidden-sm-down">Lees meer</span>
        </router-link>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ObservedImage from '../ObservedImage.vue';

    export default {
        name: 'CardInterior',
        components: { ObservedImage },
        props: {
            card: {
                type: Object,
                required: true,
            },
            index: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
            },
        },
        computed: {
            ...mapGetters('summary', {
                area: 'getSurfaceArea',
                isSelected: 'isSelected',
            }),
        },
        methods: {
            onChange (event, payload) {
                if (typeof payload.is_available !== 'undefined' && !payload.is_available) return;

                // get kantoor quantity
                const kantoor = this.$store.state.summary.role.find((node) => node.name === 'Kantoor');
                const kantoorArea = typeof kantoor !== 'undefined' ? kantoor.quantity : 0;

                // get showroom quantity
                const showroom = this.$store.state.summary.role.find((node) => node.name === 'Showroom');
                const showroomArea = typeof showroom !== 'undefined' ? showroom.quantity : 0;

                payload.quantity = kantoorArea + showroomArea;

                // add the summary
                this.$store.dispatch('summary/addToSummary', {
                    payload,
                    index: this.index,
                    type: this.type,
                });

                // update tab
                this.$store.dispatch('tabs/updateTab', {
                    index: this.index,
                    type: this.type,
                });

                // emit event to the parent
                this.$emit('selectedImage', payload.image);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-interior {
        padding: 0;
        border-width: rem(3);
        height: 100%;
        margin: 0 rem(10);
        width: 70%;

        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            margin: 0;
            height: rem(150);
            width: 100%;
        }

        &:before {
            @include fill;
            background-color: rgba(black, .4);
            content: "";
            display: block;
            transition: $transition-base;
            z-index: 1;
        }

        &__img {
            @include square(100%);
            /*opacity: 0;
            transition: $transition-base;

            &.is-downloaded {
                opacity: 1;
            }*/
        }

        @include hover-focus {
            border-color: $white;

            &:before {
                background-color: rgba(black, 0);
            }
        }

        &.is-disabled {
            opacity: 0.5;
        }

        // IE

        &:nth-child(1) { -ms-grid-column: 1; }
        &:nth-child(2) { -ms-grid-column: 2; }
        &:nth-child(3) { -ms-grid-column: 3; }
    }

    .custom-control-label {
        @include fill;
        margin-bottom: 0;
        justify-content: center;
        z-index: 2;

        @include media-breakpoint-up(md) {
            padding: rem(30) rem(40);
        }

        .is-disabled & {
            opacity: 1;
        }
    }

    .custom-control-name {
        align-self: center;
        color: $white;

        &:before {
            @include square(rem(28));
        }

        &:after {
            left: rem(6);
        }
    }
</style>
