<template>
    <div class="o-container">
        <app-intro>
            <h1>Kies uw interieur</h1>
            <h3>Welke interieurstijl past het best bij uw bedrijf?</h3>
        </app-intro>

        <loading v-bind:nodes="nodes">Bezig met laden van de interieur...</loading>

        <section class="c-interiors">
            <transition name="fade" mode="out-in">
                <observed-image class-name="c-interiors__img u-cover" :src="selectedImage" :key="selectedImage"/>
            </transition>

            <div class="c-interiors__list">
                <div class="c-interiors__alert" v-show="disabled">U kunt geen interieur kiezen omdat er alleen voor de functie opslag gekozen is</div>

                <flickity class="c-interiors__slider" ref="flickity" :options="flickityOptions">
                    <card-interior v-on:selectedImage="selectedImage = $event" v-bind:card="node" :disabled="disabled" :type="type" :index="index" v-for="node in nodes" :key="node.id"></card-interior>
                </flickity>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import Flickity from 'vue-flickity';
    import AppIntro from '../components/app/AppIntro.vue';
    import Loading from '../components/Loading.vue';
    import CardInterior from '../components/cards/CardInterior.vue';
    import ObservedImage from '../components/ObservedImage.vue';
    import setStartingValues from '../mixins/setStartingValues';

    export default {
        mixins: [setStartingValues],
        components: {
            ObservedImage,
            Flickity,
            CardInterior,
            Loading,
            AppIntro,
        },
        data () {
            return {
                index: 'interior',
                type: 'radio',
                selectedImage: '',
                disabled: false,
                flickityOptions: {
                    cellSelector: '.c-interior',
                    watchCSS: true,
                    pageDots: false,
                    wrapAround: true,
                },
            };
        },
        computed: {
            ...mapState({
                nodes: (state) => state.interior.all,
            }),
            ...mapGetters('summary', {
                area: 'getSurfaceArea',
            }),
        },
        methods: {
            ...mapActions('tabs', ['enableTab']),
        },
        async mounted () {
            this.$store.commit('tabs/setCurrentTab', this.$route.name);
            await this.$store.dispatch('interior/getAll');

            // check if role is only "opslag"
            const roles = this.$store.state.summary.role.map((node) => node.name);

            if (roles.length === 1 && roles.includes('Opslag'))
            {
                // reset the prices
                for (const node of this.nodes)
                {
                    node.price_m2 = 0;
                }

                // flag as disabled
                this.disabled = true;

                // enable next tabs
                this.enableTab(this.$store.getters['tabs/getNextTab']);
            }
            else
            {
                // set selected image (from store or default selection)
                const storeItem = this.$store.state.summary[this.index].find((node) => node.id);
                const node = this.nodes.find((node) => node.is_default);
                this.selectedImage = typeof storeItem !== 'undefined' ? storeItem.image : typeof node !== 'undefined' ? node.image : '';

                // reset flag
                this.disabled = false;

                this.setStartingValues();
            }

            // rerender flickity else the slider is not initialsed
            this.$refs.flickity.rerender();
        },
        beforeRouteEnter (to, from, next) {
            next((vm) => {
                const { tabs } = vm.$store.state;
                const isDisabled = tabs.tabs[tabs.currentTab].disabled;

                if (isDisabled)
                {
                    const index = 'interior';
                    const nextTabs = vm.$store.getters['tabs/getNextTabsFromIndex'](index);
                    vm.$store.dispatch('tabs/disableTabs', nextTabs);
                    vm.$store.dispatch('summary/resetSummary', [index]);
                    next('/');
                }
                else
                {
                    next();
                }
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/essentials";

    .c-intro {
        &,
        h1 {
            color: $white;
        }
    }

    .c-interiors {
        @include fill;
        background-color: $gray-dark;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 33vh;
        height: calc(100vh - #{rem(map_get($header-height, xs))} - #{rem(map_get($footer-height, xs))});
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            grid-template-rows: 1fr 25vh;
            height: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
        }

        @include media-breakpoint-up(md) {
            grid-template-rows: 1fr rem(200);
        }

        &:before {
            @include absolute(top 0 left 0 bottom 50% right 0);
            @include gradient-y(rgba(black, .58), rgba(black, 0));
            content: "";
            display: block;
            z-index: 1;
        }

        &__img {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
            /*opacity: 0;
            transition: $transition-base;

            &.is-downloaded {
                opacity: 1;
            }*/
        }

        &__slider {
            height: 100%;

            @include media-breakpoint-up(md) {
                align-items: center;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: rem(20);
                max-width: 100%;
                margin: 0 auto;
            }

            @include media-breakpoint-up(lg) {
                max-width: 75%;
            }

            &:after {
                content: 'flickity';
                display: none;

                @include media-breakpoint-up(md) {
                    content: '';
                }
            }
        }

        &__list {
            background-color: rgba($black, .74);
            grid-column: 1;
            grid-row: 2;
            padding: rem(30) 0;
            position: relative;
            z-index: 2;

            @include media-breakpoint-up(md) {
                padding: 0 10%;
            }

            @include media-breakpoint-up(xl) {
                padding: 0 15%;
            }
        }

        &__alert {
            @include absolute(top rem(-48) left 0 right 0);
            background-color: $brand-danger;
            color: $white;
            display: block;
            padding: rem(10) rem(20);
            text-align: center;
            z-index: 100;
        }
    }
</style>
